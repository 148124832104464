<template>
  <v-container>
    <Topbar/>
    <v-app class="greg" :style="{background: $vuetify.theme.themes.dark.background}">
      <v-container>
        <v-row class="mt-n12 mb-n12"
        >

        </v-row>
        <v-row>
          <v-toolbar color="rgba(0,0,0,0)" flat>

            <v-spacer></v-spacer>
          </v-toolbar>
        </v-row>
        <v-item-group mandatory class="mt-n12">
          <v-container>
            <v-row justify="center" class="space pt-8">
              <v-col
                  cols="12"
                  md="2"
              >
                <v-item v-slot="{ active, toggle }" >
                  <v-card
                      :color="active ? '#E69C3B' : 'white'"
                      elevation="2"
                      class="d-flex align-center rounded-lg mx-2"
                      dark
                      height="60"
                      @click="toggle"
                      flat
                  >
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-list-item three-line  class="text-center" @click="getProducts">
                          <v-list-item-content>
                            <div align="center" justify="center">

                              <v-list-item-subtitle
                                  :class="active ? 'white--text' : 'black--text'" class=" caption mt-4"> Tous les produits</v-list-item-subtitle>

                            </div>

                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-item>
              </v-col>
              <v-col
                  cols="12"
                  md="2"
              >
                <v-item v-slot="{ active, toggle }" >
                  <v-card
                      :color="active ? '#E69C3B' : 'white'"
                      elevation="2"
                      class="d-flex align-center rounded-lg mx-2"
                      dark
                      height="60"
                      @click="toggle"
                      flat
                  >
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-list-item three-line  class="text-center " @click="getProductsFS">
                          <v-list-item-content>
                            <div align="center" justify="center">

                              <v-img  :src="require('../assets/catalogue/Fruits-secs.svg')" max-height="30" max-width="30" contain></v-img>

                            </div>
                            <v-list-item-subtitle
                                :class="active ? 'white--text' : 'black--text'" class=" caption ">Fruits secs</v-list-item-subtitle>

                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-item>
              </v-col>
              <v-col
                  cols="12"
                  md="2"
              >
                <v-item v-slot="{ active, toggle }" >
                  <v-card
                      :color="active ? '#E69C3B' : 'white'"
                      elevation="2"
                      class="d-flex align-center rounded-lg mx-2"
                      dark
                      height="60"
                      @click="toggle"
                      flat
                  >
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-list-item three-line  class="text-center " @click="getProductsBC">
                          <v-list-item-content>
                            <div align="center" justify="center">

                              <v-img  :src="require('../assets/catalogue/boissons-chaudes.svg')" max-height="30" max-width="30" contain></v-img>

                            </div>
                            <v-list-item-subtitle
                                :class="active ? 'white--text' : 'black--text'" class=" caption mt-3">Boissons chaudes</v-list-item-subtitle>

                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-item>
              </v-col>
              <v-col
                  cols="12"
                  md="2"
              >
                <v-item v-slot="{ active, toggle }" >
                  <v-card
                      :color="active ? '#E69C3B' : 'white'"
                      class="d-flex align-center rounded-lg mx-2"
                      elevation="2"
                      dark
                      height="60"
                      @click="toggle"
                      flat
                  >
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-list-item three-line  class="text-center " @click="getProductsTex">
                          <v-list-item-content>
                            <v-list-item-subtitle
                                :class="active ? 'white--text' : 'black--text'" class=" caption mt-3">
                              <div align="center" justify="center">
                                <v-img  :src="require('../assets/catalogue/textile.svg')" max-height="30" max-width="30" contain></v-img>
                              </div>
                              Textile
                            </v-list-item-subtitle>

                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-item>
              </v-col>
              <v-col
                  cols="12"
                  md="2"
              >
                <v-item v-slot="{ active, toggle }" >
                  <v-card
                      :color="active ? '#E69C3B' : 'white'"
                      elevation="2"
                      class="d-flex align-center rounded-lg mx-2"
                      dark
                      height="60"
                      @click="toggle"
                      flat
                  >
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-list-item three-line  class="text-center " @click="getProductsCom">
                          <v-list-item-content>
                            <v-list-item-subtitle
                                :class="active ? 'white--text' : 'black--text'" class=" caption mt-3">
                              <div align="center" justify="center">
                                <v-img  :src="require('../assets/catalogue/communication.svg')" max-height="30" max-width="30" contain></v-img>
                              </div>
                              Communication</v-list-item-subtitle>

                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-item>
              </v-col>
              <v-col
                  cols="12"
                  md="2"
              >
                <v-item v-slot="{ active, toggle }" >
                  <v-card
                      :color="active ? '#E69C3B' : 'white'"
                      elevation="2"
                      class="d-flex align-center rounded-lg mx-2"
                      dark
                      height="60"
                      @click="toggle"
                      flat
                  >
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-list-item three-line  class="text-center " @click="getProductsAni">
                          <v-list-item-content>
                            <v-list-item-subtitle
                                :class="active ? 'white--text' : 'black--text'" class=" caption mt-3">
                              <div align="center" justify="center">
                                <v-img  :src="require('../assets/catalogue/Animations.svg')" max-height="30" max-width="30" contain></v-img>
                              </div>
                              Animation
                            </v-list-item-subtitle>

                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-item>
              </v-col>
              <v-col
                  cols="12"
                  md="2"
              >
                <v-item v-slot="{ active, toggle }" >
                  <v-card
                      :color="active ? '#E69C3B' : 'white'"
                      elevation="2"
                      class="d-flex align-center rounded-lg mx-2"
                      dark
                      height="60"
                      @click="toggle"
                      flat
                  >
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-list-item three-line  class="text-center " @click="getProductsMat">
                          <v-list-item-content>
                            <v-list-item-subtitle
                                :class="active ? 'white--text' : 'black--text'" class=" caption mt-3"><div align="center" justify="center">

                              <v-img  :src="require('../assets/catalogue/materiel.svg')" max-height="30" max-width="30" contain></v-img>
                            </div>
                              Petit matériel
                            </v-list-item-subtitle>

                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-item>
              </v-col>

            </v-row>

          </v-container>
        </v-item-group>
        <v-toolbar  flat>
          <v-toolbar-title></v-toolbar-title><v-spacer></v-spacer>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="4"
                 v-for="product in products" :key="product.id">
            <v-hover v-slot="{ hover }">
              <v-card
                  class="mx-auto"
                  color="grey lighten-4"
                  max-width="390"
                  height="350"
              >
                <v-img
                    :aspect-ratio="16/9"
                    :src="product.image[0]"
                >
                  <v-expand-transition>
                    <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out teal accent-4 darken-2 v-card--reveal text-h2 white--text"
                        style="height: 100%;"
                    >
                      {{ product.price }} €
                    </div>
                  </v-expand-transition>
                </v-img>
                <v-card-text
                    class="pt-6"
                    style="position: relative;"
                >
                  <AddToCart
                      :p-id="product.id"
                      :price="product.price"
                      :name="product.name"
                      :quantity="quantity"
                  />
                  <h3 class="text-h5 font-weight orange--text mb-2">
                    <router-link :to="{name: 'productDetail', params: {id : product.productId}}" class="textgreg orange--text">
                      {{ product.name }}
                    </router-link>
                  </h3>
                  <div class="font-weight-light text-h7 mb-2">
                    <strong>{{ product.conditionnement }}</strong>
                  </div>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>

    </v-app>
  </v-container>
</template>

<script>
import {collection, getDocs, query } from "firebase/firestore";
import {db} from "../main";
import AddToCart from "../components/AddToCart";
import {mapGetters} from "vuex";
import Topbar from "../components/Topbar";

export default {
  name: 'Home',
  props: {
    count: Number
  },
  data: () => ({
    valid: true,
    dialog: false,
    products:null,
    product:{
      id: '',
      name: '',
      description: '',
      price: null,
      image: null,
      quantity: 1,
    },
    quantity: 1,
    checkbox: false,
  }),
  components: {
    Topbar,
    AddToCart,
  },
  computed : {
    ...mapGetters(["currentUser", "cart"]),
    displayProduct: function(){
      return this.getProducts()
    },
    majQte(){
      return this.quantity
    }
  },
  methods: {
    async getProducts(){
      this.products = []
      const q = query(collection(db, "products"));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        this.products.push(doc.data());
      });
    },
    async getProductsFS(){
      this.products = []
      db.collection('products')
          .where('category', '==', 'Fruits Secs').get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              console.log(doc.id, " => ", doc.data());

              this.products.push(doc.data());
            })
            this.loaded = true
          })
    },
    async getProductsBC(){
      this.products = []
      db.collection('products')
          .where('category', '==', 'Boissons Chaudes').get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              console.log(doc.id, " => ", doc.data());

              this.products.push(doc.data());
            })
            this.loaded = true
          })
    },
    async getProductsTex(){
      this.products = []
      db.collection('products')
          .where('category', '==', 'Textile').get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              console.log(doc.id, " => ", doc.data());
              this.products.push(doc.data());
            })
            this.loaded = true
          })
    },
    async getProductsCom(){
      this.products = []
      db.collection('products')
          .where('category', '==', 'Communication').get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              console.log(doc.id, " => ", doc.data());
              this.products.push(doc.data());
            })
            this.loaded = true
          })
    },
    async getProductsAni(){
      this.products = []
      db.collection('products')
          .where('category', '==', 'Evénementiel').get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              console.log(doc.id, " => ", doc.data());
              this.products.push(doc.data());
            })
            this.loaded = true
          })
    },
    async getProductsMat(){
      this.products = []
      db.collection('products')
          .where('category', '==', 'Petit Matériel').get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              console.log(doc.id, " => ", doc.data());
              this.products.push(doc.data());
            })
            this.loaded = true
          })
    },
  },
  created() {
    this.getProducts()
  }
}
</script>
<style>
.v-card.borderme {
  border: 2px solid #704232 !important;
}
.v-card.borderout{
  border: 1px solid #BCAAA4 !important;
}
.greg {
  background-color: #de4b29;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .9;
  position: absolute;
  width: 100%;
}
.textgreg{
  text-decoration: none;
}
</style>
