var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('Topbar'),_c('v-app',{staticClass:"greg",style:({background: _vm.$vuetify.theme.themes.dark.background})},[_c('v-container',[_c('v-row',{staticClass:"mt-n12 mb-n12"}),_c('v-row',[_c('v-toolbar',{attrs:{"color":"rgba(0,0,0,0)","flat":""}},[_c('v-spacer')],1)],1),_c('v-item-group',{staticClass:"mt-n12",attrs:{"mandatory":""}},[_c('v-container',[_c('v-row',{staticClass:"space pt-8",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-lg mx-2",attrs:{"color":active ? '#E69C3B' : 'white',"elevation":"2","dark":"","height":"60","flat":""},on:{"click":toggle}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-list-item',{staticClass:"text-center",attrs:{"three-line":""},on:{"click":_vm.getProducts}},[_c('v-list-item-content',[_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-list-item-subtitle',{staticClass:" caption mt-4",class:active ? 'white--text' : 'black--text'},[_vm._v(" Tous les produits")])],1)])],1)],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-lg mx-2",attrs:{"color":active ? '#E69C3B' : 'white',"elevation":"2","dark":"","height":"60","flat":""},on:{"click":toggle}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-list-item',{staticClass:"text-center ",attrs:{"three-line":""},on:{"click":_vm.getProductsFS}},[_c('v-list-item-content',[_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-img',{attrs:{"src":require('../assets/catalogue/Fruits-secs.svg'),"max-height":"30","max-width":"30","contain":""}})],1),_c('v-list-item-subtitle',{staticClass:" caption ",class:active ? 'white--text' : 'black--text'},[_vm._v("Fruits secs")])],1)],1)],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-lg mx-2",attrs:{"color":active ? '#E69C3B' : 'white',"elevation":"2","dark":"","height":"60","flat":""},on:{"click":toggle}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-list-item',{staticClass:"text-center ",attrs:{"three-line":""},on:{"click":_vm.getProductsBC}},[_c('v-list-item-content',[_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-img',{attrs:{"src":require('../assets/catalogue/boissons-chaudes.svg'),"max-height":"30","max-width":"30","contain":""}})],1),_c('v-list-item-subtitle',{staticClass:" caption mt-3",class:active ? 'white--text' : 'black--text'},[_vm._v("Boissons chaudes")])],1)],1)],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-lg mx-2",attrs:{"color":active ? '#E69C3B' : 'white',"elevation":"2","dark":"","height":"60","flat":""},on:{"click":toggle}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-list-item',{staticClass:"text-center ",attrs:{"three-line":""},on:{"click":_vm.getProductsTex}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:" caption mt-3",class:active ? 'white--text' : 'black--text'},[_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-img',{attrs:{"src":require('../assets/catalogue/textile.svg'),"max-height":"30","max-width":"30","contain":""}})],1),_vm._v(" Textile ")])],1)],1)],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-lg mx-2",attrs:{"color":active ? '#E69C3B' : 'white',"elevation":"2","dark":"","height":"60","flat":""},on:{"click":toggle}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-list-item',{staticClass:"text-center ",attrs:{"three-line":""},on:{"click":_vm.getProductsCom}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:" caption mt-3",class:active ? 'white--text' : 'black--text'},[_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-img',{attrs:{"src":require('../assets/catalogue/communication.svg'),"max-height":"30","max-width":"30","contain":""}})],1),_vm._v(" Communication")])],1)],1)],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-lg mx-2",attrs:{"color":active ? '#E69C3B' : 'white',"elevation":"2","dark":"","height":"60","flat":""},on:{"click":toggle}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-list-item',{staticClass:"text-center ",attrs:{"three-line":""},on:{"click":_vm.getProductsAni}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:" caption mt-3",class:active ? 'white--text' : 'black--text'},[_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-img',{attrs:{"src":require('../assets/catalogue/Animations.svg'),"max-height":"30","max-width":"30","contain":""}})],1),_vm._v(" Animation ")])],1)],1)],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-lg mx-2",attrs:{"color":active ? '#E69C3B' : 'white',"elevation":"2","dark":"","height":"60","flat":""},on:{"click":toggle}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-list-item',{staticClass:"text-center ",attrs:{"three-line":""},on:{"click":_vm.getProductsMat}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:" caption mt-3",class:active ? 'white--text' : 'black--text'},[_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-img',{attrs:{"src":require('../assets/catalogue/materiel.svg'),"max-height":"30","max-width":"30","contain":""}})],1),_vm._v(" Petit matériel ")])],1)],1)],1)],1)],1)]}}])})],1)],1)],1)],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_c('v-spacer')],1),_c('v-row',_vm._l((_vm.products),function(product){return _c('v-col',{key:product.id,attrs:{"cols":"12","sm":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"color":"grey lighten-4","max-width":"390","height":"350"}},[_c('v-img',{attrs:{"aspect-ratio":16/9,"src":product.image[0]}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out teal accent-4 darken-2 v-card--reveal text-h2 white--text",staticStyle:{"height":"100%"}},[_vm._v(" "+_vm._s(product.price)+" € ")]):_vm._e()])],1),_c('v-card-text',{staticClass:"pt-6",staticStyle:{"position":"relative"}},[_c('AddToCart',{attrs:{"p-id":product.id,"price":product.price,"name":product.name,"quantity":_vm.quantity}}),_c('h3',{staticClass:"text-h5 font-weight orange--text mb-2"},[_c('router-link',{staticClass:"textgreg orange--text",attrs:{"to":{name: 'productDetail', params: {id : product.productId}}}},[_vm._v(" "+_vm._s(product.name)+" ")])],1),_c('div',{staticClass:"font-weight-light text-h7 mb-2"},[_c('strong',[_vm._v(_vm._s(product.conditionnement))])])],1)],1)]}}],null,true)})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }